
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "3d5f47e6-1bee-4614-a8d8-9249c572d6ce"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
